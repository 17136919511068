<template>
  <div class="header">
    <van-icon
      name="arrow-left"
      class="back"
      @click="$router.go(-1)"
      v-if="back"
    />
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      require: true,
      type: String,
    },
    back: {
      require: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  background: #ffffff;
  color: #333333;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;

  .back {
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 20%;
    left: 5px;
  }
}
</style>
