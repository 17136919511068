var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process"},[_c('header-common',{attrs:{"title":"处理事件","back":""}}),(_vm.evidenceData.eventType == 2)?_c('div',[_c('van-cell-group',{attrs:{"title":"变化后"}},[_c('van-cell',{attrs:{"title":"时间","value":_vm.evidenceData.evidence2.after.changeTime}}),_c('van-cell',{attrs:{"title":"仪表名称","value":_vm.evidenceData.evidence2.after.devicename}}),_c('van-cell',{attrs:{"title":"参数名称","value":_vm.evidenceData.evidence2.after.dmParamname}}),_c('van-cell',{attrs:{"title":"参数标准值","value":_vm.evidenceData.evidence2.after.dmStandardFloor +
          ' - ' +
          _vm.evidenceData.evidence2.after.dmStandardCeiling}}),_c('van-cell',{attrs:{"title":"参数值","value":_vm.evidenceData.evidence2.after.dmValue
            ? _vm.evidenceData.evidence2.after.dmValue
            : '-'}}),_c('van-cell',{attrs:{"title":"监测因子名称","value":_vm.evidenceData.evidence2.after.pname}}),_c('van-cell',{attrs:{"title":"监测因子标准值","value":_vm.evidenceData.evidence2.after.pstandardFloor +
          ' - ' +
          _vm.evidenceData.evidence2.after.pstandardCeiling}}),_c('van-cell',{attrs:{"title":"监测因子值","value":_vm.evidenceData.evidence2.after.pvalue
            ? _vm.evidenceData.evidence2.after.pvalue
            : '-'}})],1)],1):_vm._e(),(_vm.evidenceData.eventType == 6)?_c('div',[_c('van-cell-group',{attrs:{"title":"变化后"}},[_c('van-cell',{attrs:{"title":"时间","value":_vm.evidenceData.evidence6.after.changeTime}}),_c('van-cell',{attrs:{"title":"监测因子名称","value":_vm.evidenceData.evidence6.after.pname}}),_c('van-cell',{attrs:{"title":"监测因子标准值","value":_vm.evidenceData.evidence6.after.pstandardFloor +
          ' - ' +
          _vm.evidenceData.evidence6.after.pstandardCeiling}}),_c('van-cell',{attrs:{"title":"监测因子值","value":_vm.evidenceData.evidence6.after.pvalue
            ? _vm.evidenceData.evidence6.after.pvalue
            : '-'}})],1)],1):_vm._e(),(_vm.evidenceData.eventType == 9)?_c('div',[_c('van-notice-bar',{attrs:{"wrapable":"","scrollable":false,"text":_vm.evidenceData.evidence9.enterpriseName +
        '企业 ' +
        _vm.evidenceData.evidence9.pointName +
        ' 站点 未按时上报 ' +
        _vm.evidenceData.evidence9.dataTime +
        ' 日数据。'}})],1):_vm._e(),(_vm.evidenceData.eventType == 10)?_c('div',[_c('van-cell-group',{attrs:{"title":"事件信息"}},[_c('van-cell',{attrs:{"title":"事件时间","value":_vm.evidenceData.eventTime}}),_c('van-cell',{attrs:{"title":"事件说明","value":_vm.eventInfo.describe}}),_c('van-cell',{attrs:{"title":"监测因子名称","value":_vm.evidenceData.evidence10.after.pname}})],1)],1):_vm._e(),(_vm.evidenceData.eventType == 11)?_c('div',[_c('van-cell-group',{attrs:{"title":"事件信息"}},[_c('van-cell',{attrs:{"title":"事件时间","value":_vm.evidenceData.eventTime}}),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"事件说明","value":_vm.eventInfo.describe}})],1),_c('van-cell',{attrs:{"title":"监测因子名称","value":_vm.evidenceData.evidence11.divisorName}}),_c('van-cell',{attrs:{"title":"监测因子值","value":_vm.evidenceData.evidence11.divisorValue
            ? _vm.evidenceData.evidence11.divisorValue
            : '-'}})],1)],1):_vm._e(),(_vm.evidenceData.eventType == 12)?_c('div',[_c('van-notice-bar',{attrs:{"wrapable":"","scrollable":false,"text":_vm.evidenceData.evidence12.enterpriseName +
        '企业 ' +
        _vm.evidenceData.evidence12.pointName +
        ' 站点 于 ' +
        _vm.evidenceData.evidence12.dataTime +
        ' 出现数采仪断线情况。'}})],1):_vm._e(),(_vm.evidenceData.eventType == 13)?_c('div',[_c('ve-line',{attrs:{"heigth":"300px","data":_vm.chartData13,"settings":_vm.settings13,"extend":_vm.extend13}})],1):_vm._e(),(_vm.evidenceData.eventType == 15)?_c('div',[_c('van-notice-bar',{attrs:{"wrapable":"","scrollable":false,"text":_vm.evidenceData.evidence15.enterpriseName +
        '企业 ' +
        _vm.evidenceData.evidence15.pointName +
        ' 站点 于 ' +
        _vm.evidenceData.evidence15.dataTime +
        ' 出现 ' +
        _vm.evidenceData.evidence15.deviceName +
        ' 仪表设备故障情况。'}})],1):_vm._e(),(_vm.busId)?_c('div',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.$api.base.file + '/zzfile/snap/' + _vm.busId + '.jpg',"alt":""}})]):_vm._e(),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"预警时间","value":_vm.eventInfo.warningTime}}),_c('van-cell',{attrs:{"title":"事件级别"}},[(_vm.eventInfo.leve == 1)?_c('van-tag',{attrs:{"type":"danger"}},[_vm._v("一级事件")]):_vm._e(),(_vm.eventInfo.leve == 2)?_c('van-tag',{attrs:{"type":"warning"}},[_vm._v("二级事件")]):_vm._e(),(_vm.eventInfo.leve == 3)?_c('van-tag',{attrs:{"type":"primary"}},[_vm._v("三级事件")]):_vm._e(),(_vm.eventInfo.leve == 4)?_c('van-tag',[_vm._v("巡查事件")]):_vm._e()],1)],1),_c('van-field',{attrs:{"rows":"2","autosize":"","label":"企业处理","type":"textarea","maxlength":"100","placeholder":"请输入处理结果","show-word-limit":"","rules":[{ required: true, message: '请填写处理结果' }]},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('van-uploader',{attrs:{"max-count":9,"after-read":_vm.afterRead,"before-read":_vm.beforeRead},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('van-button',{attrs:{"type":"primary","block":""},on:{"click":_vm.processEnterprise}},[_vm._v("提交处理结果")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }