<template>
  <div class="process">
    <header-common title="处理事件" back></header-common>
    <!-- 1疑似人为干扰采样环境 -->
    <!-- 无 -->

    <!-- 2疑似修改分析仪器关键参数 -->
    <div v-if="evidenceData.eventType == 2">
      <!-- <van-cell-group title="变化前">
        <van-cell
          title="时间"
          :value="evidenceData.evidence2.before.changeTime"
        />
        <van-cell
          title="仪表名称"
          :value="evidenceData.evidence2.before.devicename"
        />
        <van-cell
          title="参数名称"
          :value="evidenceData.evidence2.before.dmParamname"
        />
        <van-cell
          title="参数标准值"
          :value="
            evidenceData.evidence2.before.dmStandardFloor +
              ' - ' +
              evidenceData.evidence2.before.dmStandardCeiling
          "
        />
        <van-cell
          title="参数值"
          :value="
            evidenceData.evidence2.before.dmValue
              ? evidenceData.evidence2.before.dmValue
              : '-'
          "
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence2.before.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence2.before.pstandardFloor +
              ' - ' +
              evidenceData.evidence2.before.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence2.before.pvalue
              ? evidenceData.evidence2.before.pvalue
              : '-'
          "
        />
      </van-cell-group> -->
      <van-cell-group title="变化后">
        <van-cell
          title="时间"
          :value="evidenceData.evidence2.after.changeTime"
        />
        <van-cell
          title="仪表名称"
          :value="evidenceData.evidence2.after.devicename"
        />
        <van-cell
          title="参数名称"
          :value="evidenceData.evidence2.after.dmParamname"
        />
        <van-cell
          title="参数标准值"
          :value="
            evidenceData.evidence2.after.dmStandardFloor +
            ' - ' +
            evidenceData.evidence2.after.dmStandardCeiling
          "
        />
        <van-cell
          title="参数值"
          :value="
            evidenceData.evidence2.after.dmValue
              ? evidenceData.evidence2.after.dmValue
              : '-'
          "
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence2.after.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence2.after.pstandardFloor +
            ' - ' +
            evidenceData.evidence2.after.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence2.after.pvalue
              ? evidenceData.evidence2.after.pvalue
              : '-'
          "
        />
      </van-cell-group>
    </div>

    <!-- 3疑似违规生产 -->
    <!-- 无 -->

    <!-- 4疑似长时间关停治理设施 -->
    <!-- 无 -->

    <!-- 5疑似污染防治设施不正常运行 -->
    <!-- 无 -->

    <!-- 6疑似浓度超标违规 -->
    <div v-if="evidenceData.eventType == 6">
      <!-- <van-cell-group title="变化前">
        <van-cell
          title="时间"
          :value="evidenceData.evidence6.before.changeTime"
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence6.before.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence6.before.pstandardFloor +
              ' - ' +
              evidenceData.evidence6.before.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence6.before.pvalue
              ? evidenceData.evidence6.before.pvalue
              : '-'
          "
        />
      </van-cell-group> -->
      <van-cell-group title="变化后">
        <van-cell
          title="时间"
          :value="evidenceData.evidence6.after.changeTime"
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence6.after.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence6.after.pstandardFloor +
            ' - ' +
            evidenceData.evidence6.after.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence6.after.pvalue
              ? evidenceData.evidence6.after.pvalue
              : '-'
          "
        />
      </van-cell-group>
    </div>

    <!-- 7疑似总量超量违规 -->
    <!-- 无 -->

    <!-- 8疑似非法进入站房 -->
    <!-- 无 -->

    <!-- 9监测数据缺失 -->
    <div v-if="evidenceData.eventType == 9">
      <van-notice-bar
        wrapable
        :scrollable="false"
        :text="
          evidenceData.evidence9.enterpriseName +
          '企业 ' +
          evidenceData.evidence9.pointName +
          ' 站点 未按时上报 ' +
          evidenceData.evidence9.dataTime +
          ' 日数据。'
        "
      />
    </div>

    <!-- 10监测数据连续零值 -->
    <div v-if="evidenceData.eventType == 10">
      <van-cell-group title="事件信息">
        <van-cell title="事件时间" :value="evidenceData.eventTime" />
        <van-cell title="事件说明" :value="eventInfo.describe" />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence10.after.pname"
        />
      </van-cell-group>
    </div>
    <div v-if="evidenceData.eventType == 11">
      <van-cell-group title="事件信息">
        <van-cell title="事件时间" :value="evidenceData.eventTime" />
        <van-cell-group>
          <van-cell title="事件说明" :value="eventInfo.describe" />
        </van-cell-group>
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence11.divisorName"
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence11.divisorValue
              ? evidenceData.evidence11.divisorValue
              : '-'
          "
        />
      </van-cell-group>
    </div>
    <!-- <div v-if="evidenceData.eventType == 10">
      <ve-line
        heigth="300px"
        :data="chartData10"
        :settings="settings10"
        :extend="extend10"
      ></ve-line>
    </div> -->
    <!-- 11监测数据连续不变 -->
    <!-- <div v-if="evidenceData.eventType == 11">
      <ve-line
        heigth="300px"
        :data="chartData11"
        :settings="settings11"
        :extend="extend11"
      ></ve-line>
    </div> -->

    <!-- 12数采仪断线 -->
    <div v-if="evidenceData.eventType == 12">
      <van-notice-bar
        wrapable
        :scrollable="false"
        :text="
          evidenceData.evidence12.enterpriseName +
          '企业 ' +
          evidenceData.evidence12.pointName +
          ' 站点 于 ' +
          evidenceData.evidence12.dataTime +
          ' 出现数采仪断线情况。'
        "
      />
    </div>

    <!-- 13监测值陡升陡降 -->
    <div v-if="evidenceData.eventType == 13">
      <ve-line
        heigth="300px"
        :data="chartData13"
        :settings="settings13"
        :extend="extend13"
      ></ve-line>
    </div>

    <!-- 14仪表设备断线 -->
    <!-- 无 -->

    <!-- 15仪表设备故障 -->
    <div v-if="evidenceData.eventType == 15">
      <van-notice-bar
        wrapable
        :scrollable="false"
        :text="
          evidenceData.evidence15.enterpriseName +
          '企业 ' +
          evidenceData.evidence15.pointName +
          ' 站点 于 ' +
          evidenceData.evidence15.dataTime +
          ' 出现 ' +
          evidenceData.evidence15.deviceName +
          ' 仪表设备故障情况。'
        "
      />
    </div>
    <div v-if="busId">
      <img
        :src="$api.base.file + '/zzfile/snap/' + busId + '.jpg'"
        alt=""
        style="width: 100%"
      />
    </div>
    <van-cell-group>
      <van-cell title="预警时间" :value="eventInfo.warningTime" />
      <van-cell title="事件级别">
        <van-tag type="danger" v-if="eventInfo.leve == 1">一级事件</van-tag>
        <van-tag type="warning" v-if="eventInfo.leve == 2">二级事件</van-tag>
        <van-tag type="primary" v-if="eventInfo.leve == 3">三级事件</van-tag>
        <van-tag v-if="eventInfo.leve == 4">巡查事件</van-tag>
      </van-cell>
      <!-- <van-cell title="事件说明" :value="eventInfo.describe" /> -->
    </van-cell-group>
    <van-field
      v-model="message"
      rows="2"
      autosize
      label="企业处理"
      type="textarea"
      maxlength="100"
      placeholder="请输入处理结果"
      show-word-limit
      :rules="[{ required: true, message: '请填写处理结果' }]"
    />
    <van-uploader
      v-model="file"
      :max-count="9"
      :after-read="afterRead"
      :before-read="beforeRead"
    />
    <van-button type="primary" block @click="processEnterprise"
      >提交处理结果</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
import headerCommon from "@/components/common/header";

export default {
  components: {
    headerCommon,
  },
  data() {
    return {
      uploadedFile: "",
      message: "",
      busId: "",
      eventInfo: {},
      file: [],
      evidenceData: {},
      chartData10: {
        columns: [],
        rows: [],
      },
      settings10: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend10: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
      chartData11: {
        columns: [],
        rows: [],
      },
      settings11: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend11: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
      evidence10TableData: [],
      evidence10columns: [],
      chartData13: {
        columns: [],
        rows: [],
      },
      settings13: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend13: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
    };
  },
  created() {
    this.eventInfo = JSON.parse(this.$route.query.eventInfo);
    this.getEvidenceByEventId();
  },
  methods: {
    // 事件类型10获取图表数据
    getChartData10() {
      const data = {
        pointId: this.evidenceData.evidence10.pointId,
        cn: this.evidenceData.evidence10.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData10.columns = tempColumns;
            this.settings10.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence10.pointId,
            eventType: 10,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData10.rows = res.data.data.list || [];
          });
        });
    },
    // 事件类型11获取图表数据
    getChartData11() {
      const data = {
        pointId: this.evidenceData.evidence11.pointId,
        cn: this.evidenceData.evidence11.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData11.columns = tempColumns;
            this.settings11.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence11.pointId,
            eventType: 11,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData11.rows = res.data.data.list || [];
          });
        });
    },
    // 事件类型13获取图表数据
    getChartData13() {
      const data = {
        pointId: this.evidenceData.evidence13.pointId,
        cn: this.evidenceData.evidence13.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData13.columns = tempColumns;
            this.settings13.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence13.pointId,
            eventType: 13,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData13.rows = res.data.data.list || [];
          });
        });
    },
    // 根据事件id查询证据链
    getEvidenceByEventId() {
      const data = {
        eventId: this.eventInfo.id,
      };
      this.$api.event.getEvidenceByEventId(data).then((res) => {
        if (res.data.state == 0) {
          this.evidenceData = res.data.data;
          switch (this.evidenceData.eventType) {
            case 1:
              this.busId = this.evidenceData.evidence1.busId;
              break;
            case 2:
              this.busId = this.evidenceData.evidence2.busId;
              break;
            case 6:
              this.busId = this.evidenceData.evidence6.busId;
              break;
            // case 10:
            //   this.getChartData10();
            //   break;
            // case 11:
            //   this.getChartData11();
            //   break;
            case 13:
              this.getChartData13();
              break;
            default:
              this.busId = "";
              break;
          }
          // if (this.busId) {
          //   this.setPlayerOptions();
          // }
        }
      });
    },
    beforeRead(file) {
      const flag = new RegExp(
        "[`~!@#$^&*=|{}':;',\\[\\]<>《》/?~！@#￥……&*——|{}【】‘；：”“'。，、？%]"
      );
      if (flag.test(file.name)) {
        Toast.fail({
          message: "文件名不能包含特殊字符",
        });
        return false;
      }
      return true;
    },
    afterRead() {
      const data = new FormData();
      data.append("file", this.file.length > 0 ? this.file[0].file : "");

      this.$api.event.uploadFileApi(data).then((res) => {
        this.uploadedFile = res.data.data;
      });
    },
    processEnterprise() {
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });

      console.log(this.file);

      const data = {
        eventId: this.eventInfo.id,
        lodEnterpriseReply: this.message,
        file: this.uploadedFile,
      };

      this.$api.event.processEnterprise(data).then((res) => {
        console.log(res);
        Toast.success("提交成功");
        this.$router.push("/");
      });
    },
  },
};
</script>

<style></style>
